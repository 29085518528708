import { CheckCircleOutlined, CloseCircleOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select, Space } from 'antd'
// import { useState } from 'react'

import { IRoleForm } from './types'

const { Option } = Select

function UserFieldFrom({ onFinish, loading, data }: IRoleForm): JSX.Element {
  return (
    <Form
      name="userField"
      onFinish={onFinish}
      labelAlign="left"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 12 }}
      requiredMark={false}
      initialValues={data}
    >
      <Space direction="vertical" size={18}>
        <Form.Item label="Name" help="User field name" name="name" initialValue="name">
          <Input />
        </Form.Item>
        <Form.Item label="Key" help="User field key reference" name="key" initialValue="">
          <Input />
        </Form.Item>
        <Form.Item label="Replaceable" help="User field Replaceable" name="replaceable" initialValue="">
          <Input />
        </Form.Item>
        <Form.Item label="Type" help="User field Type" name="type" initialValue="Text">
          <Select>
            <Option value="TEXT">
              <Space size={4}>
                <span>Text</span>
              </Space>
            </Option>
            <Option value="NUMBER">
              <Space size={4}>
                <span>Number</span>
              </Space>
            </Option>
          </Select>
        </Form.Item>
        <Form.Item label="Status" help="Credential status" name="status" initialValue="ENABLED">
          <Select>
            <Option value="ENABLED">
              <Space size={4}>
                <CheckCircleOutlined className="success-color" /> <span className="success-color">Enable</span>
              </Space>
            </Option>
            <Option value="DISABLED">
              <Space size={4}>
                <CloseCircleOutlined className="danger-color" /> <span className="danger-color">Disable</span>
              </Space>
            </Option>
          </Select>
        </Form.Item>

        <Form.Item className="mb-0" wrapperCol={{ span: 12, offset: 4 }}>
          <Button type="primary" htmlType="submit" loading={loading} icon={<SaveOutlined />}>
            Add User Field
          </Button>
        </Form.Item>
      </Space>
    </Form>
  )
}

export default UserFieldFrom
