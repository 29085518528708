// import VerificationForm from 'components/Form/Verification'
import { Modal } from 'antd'
import UserFieldFrom from 'components/Form/AppRole/UserFieldFrom'
import { IAddRoleProps } from './types'

function UserField({ isShowDialog, onCancel, refetchRole }: IAddRoleProps): JSX.Element {
  const onFinish = (values: any) => {
    console.log(values)
  }
  return (
    <Modal
      title="Add User Field"
      visible={isShowDialog}
      onOk={() => null}
      onCancel={onCancel}
      footer={false}
      width={700}
    >
      <UserFieldFrom onFinish={onFinish} />
    </Modal>
  )
}

export default UserField
