import { message } from 'antd'
import RoleFrom from 'components/Form/AppRole/RoleFrom'
import { Role } from 'graphQL/schemaType/role'
import useUpdateRole, { RoleInput } from 'graphQL/useUpdateRole/useUpdateRole'
import { useDispatch } from 'react-redux'
import { setRoleAction } from 'redux/role'
import store from 'redux/store'
// import ContentCard from 'components/Card/Content'
function RoleSettingPage(): JSX.Element {
  const dispatch = useDispatch()
  const role = store.getState().roleReducer?.role || undefined
  const [updateRole] = useUpdateRole({
    onCompleted() {
      message.success('Update role success!')
    },
  })
  const onFinish = (values: Role) => {
    dispatch(setRoleAction(values))
    let roleInput: RoleInput = values
    if (role.roleKey === values.roleKey)
      roleInput = {
        name: values.name,
        status: values.status,
        regisable: values.regisable,
        roleType: values.roleType,
        isDefault: values.isDefault,
        isReplaceable: values.isReplaceable,
      }
    updateRole({
      variables: {
        roleKey: role.roleKey,
        roleInput: roleInput,
      },
    })
  }
  return (
    <>
      <RoleFrom onFinish={onFinish} data={role} />
    </>
  )
}

export default RoleSettingPage
