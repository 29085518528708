import { useMutation } from '@apollo/client'
import { Button, Col, Dropdown, Menu, message, Row } from 'antd'

import { UPDATE_ORG_STATUS } from 'graphQL/schemas/organization'

import { isTokenExpiredError } from 'utils/apollo'

import { useOrganizationContext } from 'contexts/OrganizationProvider'

import api from 'constants/api'

import type { OrganizationStatus } from 'graphQL/schemaType/organization'

interface AlertActivationOrganizeProps {
  status: OrganizationStatus
}

interface ActivationMenu {
  key: string
  text: string
  value: OrganizationStatus
  iconName: string
}

function AlertActivationOrganize({ status }: AlertActivationOrganizeProps): JSX.Element {
  const { orgKey } = useOrganizationContext()

  const [updateOrgStatus] = useMutation(UPDATE_ORG_STATUS, {
    onCompleted() {
      window.location.reload()
    },
    onError(error) {
      if (!isTokenExpiredError(error.graphQLErrors)) {
        message.error(error.message)
      }
    },
    context: {
      uri: api.CORE.ADMIN,
    },
  })

  function onSelectActivationStatus(_status: OrganizationStatus) {
    updateOrgStatus({
      variables: {
        orgKey,
        status: _status,
      },
    })
  }

  const activationMenus: ActivationMenu[] = [
    {
      key: '0',
      text: 'Approve',
      value: 'APPROVED',
      iconName: 'user-check',
    },
    {
      key: '1',
      text: 'Need more information',
      value: 'NEED_MORE_INFORMATION',
      iconName: 'user-clock',
    },
    {
      key: '2',
      text: 'Reject',
      value: 'REJECTED',
      iconName: 'times-circle',
    },
    {
      key: '3',
      text: 'Suspend',
      value: 'SUSPENDED',
      iconName: 'times-circle',
    },
  ]

  function renderColor(approveStatus: OrganizationStatus): string {
    switch (approveStatus) {
      case 'APPROVED':
        return '#5cb85c'
      case 'SUSPENDED':
      case 'REJECTED':
        return '#d9534f'
      default:
      case 'NEED_MORE_INFORMATION':
        return '#f0ad4e'
    }
  }

  function renderTextAlertActivation(_status: OrganizationStatus) {
    switch (_status) {
      case 'APPROVED':
        return 'This account has been activated.'
      case 'REJECTED':
        return 'This account has been rejected.'
      case 'SUSPENDED':
        return 'This account has been Suspended.'
      default:
      case 'NEED_MORE_INFORMATION':
      case 'REVIEWING':
        return 'This account has been requested for activation.'
    }
  }

  function renderAlertStyle(_status: OrganizationStatus): React.CSSProperties {
    switch (_status) {
      case 'APPROVED':
        return {
          border: '1px solid #468B46',
          color: '#468B46',
        }
      case 'REJECTED':
      case 'SUSPENDED':
        return {
          border: '1px solid #A30404',
          color: '#A30404',
        }
      default:
        return {
          border: '1px solid #F09503',
          color: '#F09503',
        }
    }
  }

  function renderTextActivation(_status: OrganizationStatus): string {
    switch (_status) {
      case 'APPROVED':
        return 'Approve'
      case 'REJECTED':
        return 'Reject'
      case 'SUSPENDED':
        return 'Suspend'
      case 'REVIEWING':
        return 'Reviewing'
      default:
      case 'NEED_MORE_INFORMATION':
        return 'Need more information'
    }
  }

  const menu = (
    <Menu>
      {activationMenus.map((activationMenu) => (
        <Menu.Item
          key={activationMenu.key}
          style={{
            color: renderColor(activationMenu.value),
          }}
          icon={
            <i
              className={`fas fa-${activationMenu.iconName}`}
              style={{
                color: renderColor(activationMenu.value),
                marginRight: '0.2rem',
              }}
            />
          }
          onClick={() => onSelectActivationStatus(activationMenu.value)}
        >
          {activationMenu.text}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <div
      className="activation-alert-wrapper"
      style={{
        ...renderAlertStyle(status),
      }}
    >
      <Row align="middle">
        <Col span={20}>{renderTextAlertActivation(status)}</Col>
        <Col span={4}>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button className="activation-alert-button" type="ghost">
              {renderTextActivation(status)}
            </Button>
          </Dropdown>
        </Col>
      </Row>
    </div>
  )
}

export default AlertActivationOrganize
