import { CloseCircleOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { IUserFieldData } from 'components/Table/AppRole'
import UserField from 'components/Table/AppRole/UserField'
import UserFieldModel from 'components/Modal/AppRole/UserField'
// import { useThemeContext } from 'contexts/ThemeProvider/ThemeProvider'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
function UserFieldPage(): JSX.Element {
  const { t } = useTranslation()
  // const loading = false
  // const history = useHistory()
  const [isShowDialog, setIsShowDialog] = useState(false)
  const onCancelModel = () => {
    setIsShowDialog(false)
  }
  // const theme = useThemeContext()
  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setIsShowDialog(true)
        }}
      >
        <EditOutlined className="primary-color" />{' '}
        <span className="primary-color">{t('appRole:userField:table:action:edit')}</span>
      </Menu.Item>
      <Menu.Item>
        <CloseCircleOutlined className="reviewing-color" />{' '}
        <span className="reviewing-color">{t('appRole:userField:table:action:disable')}</span>
      </Menu.Item>
    </Menu>
  )
  const columns: ColumnsType<IUserFieldData> = [
    {
      title: t('appRole:userField:table:name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('appRole:userField:table:key'),
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: t('appRole:userField:table:type'),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: t('appRole:userField:table:status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      dataIndex: '',
      render: (_text, rander) => {
        return (
          <Dropdown overlay={menu}>
            <Button shape="round" type="primary">
              Action
            </Button>
          </Dropdown>
        )
      },
    },
  ]
  const dataSource: IUserFieldData[] = [
    {
      key: 'FIRST_NAME',
      type: 'Text (255)',
      title: '',
      name: 'First Name',
      required: true,
      placeholder: '',
      status: 'ENABLED',
    },
  ]
  return (
    <>
      <UserFieldModel isShowDialog={isShowDialog} onCancel={onCancelModel} refetchRole={() => {}} />
      <UserField dataSource={dataSource} rowKey={'user-filed'} columns={columns} />
    </>
  )
}

export default UserFieldPage
