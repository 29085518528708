import { Table } from 'antd'

import type { ITableProps } from '../types'
import type { IPermissonData } from './types'

function Permission({ columns, dataSource, rowKey }: ITableProps<IPermissonData>): JSX.Element {
  return (
    <Table
      className="organization-table"
      dataSource={dataSource}
      columns={columns}
      rowKey={rowKey}
      pagination={{ defaultPageSize: 8 }}
    />
  )
}

export default Permission
