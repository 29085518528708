// import { useState } from 'react'
import { Button, Space, Menu, Dropdown } from 'antd'
import { PlusCircleOutlined, EditOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { generatePath } from 'react-router'
// import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import ContentCard from 'components/Card/Content'
import AppRoleTable from 'components/Table/AppRole'
import SearchInput from 'components/Input/Search'
import PageTitle from 'components/PageTitle'

import AuthenticatedLayout from 'layouts/AuthenticatedLayout'

// import { GET_LOCALE_TEXT } from 'graphQL/schemas/organization'

// import API from 'constants/api'

import FullScreenLoading from 'components/FullScreenLoading'

import { routePaths } from '../routeConfig'

// import useGetOrganization from 'graphQL/useGetOrganization'
// import { defaultPagination } from 'graphQL/config'

// import type { Pagination as PaginationType } from 'graphQL/graphQL-service-hook'
import { ColumnsType } from 'antd/lib/table'
import { AddAppRole } from 'components/Modal/AppRole'
import { useEffect, useState } from 'react'
import { useThemeContext } from 'contexts/ThemeProvider/ThemeProvider'
import useGetRole from 'graphQL/useGetRole/useGetRole'
import { useDispatch } from 'react-redux'
import { setRoleAction } from 'redux/role'
import { Role } from 'graphQL/schemaType/role'

function AppRole(): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const loading = false
  const history = useHistory()
  const [isShowDialog, setIsShowDialog] = useState(false)
  const [dataSource, setDataSource] = useState<Role[] | undefined>([])

  const onCancelModel = () => {
    setIsShowDialog(false)
  }
  const theme = useThemeContext()
  const getRole = useGetRole()
  const refetchRole = () => {
    getRole.refetch()
  }
  useEffect(() => {
    const roleList = getRole.data?.getRole?.payload?.roleList
    setDataSource(roleList)
  }, [getRole.data])

  const menu = (role: Role) => (
    <Menu>
      <Menu.Item
        onClick={() => {
          theme.collapseHandler(true)
          dispatch(setRoleAction(role))
          history.push(
            generatePath(routePaths.appRoleSettingPage, {
              page: 'role-setting',
            })
          )
        }}
      >
        <EditOutlined className="primary-color" />{' '}
        <span className="primary-color">{t('appRole:table:button:edit')}</span>
        {/* <Link to={`appRole/update-role`} /> */}
      </Menu.Item>
      <Menu.Item>
        <CloseCircleOutlined className="reviewing-color" />{' '}
        <span className="reviewing-color">{t('appRole:table:button:disable')}</span>
        {/* <Link to={`appRole/update-role`} /> */}
      </Menu.Item>
    </Menu>
  )
  const columns: ColumnsType<Role> = [
    {
      title: 'Role Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Role Key',
      dataIndex: 'roleKey',
      key: 'roleKey',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      dataIndex: '',
      render: (_text, rander) => {
        return (
          <Dropdown overlay={menu(rander)}>
            <Button shape="round" type="primary">
              Action
            </Button>
          </Dropdown>
        )
      },
    },
  ]
  return (
    <AuthenticatedLayout>
      <AddAppRole isShowDialog={isShowDialog} onCancel={onCancelModel} refetchRole={refetchRole} />
      {loading ? (
        <FullScreenLoading />
      ) : (
        <div className="admin-content-container">
          <PageTitle
            isRootPage
            title={t('appRole:page.home')}
            extra={[
              <Button
                type="primary"
                key="create"
                onClick={() => {
                  setIsShowDialog(true)
                }}
              >
                <Space size={12}>
                  <PlusCircleOutlined /> {t('appRole:create')}
                </Space>
              </Button>,
            ]}
          />
          <ContentCard
            title={``}
            rightComponent={<SearchInput onSearch={(value) => {}} placeholder={t('appRole:search:placeholder')} />}
          >
            <AppRoleTable columns={columns} dataSource={dataSource} rowKey={'appRole'} />
          </ContentCard>
        </div>
      )}
    </AuthenticatedLayout>
  )
}

export default AppRole
