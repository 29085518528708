import { combineReducers } from 'redux'
import { CLEAR_REDUCER } from './types'
import appReducer from './application'
import testReducer from './test'
import roleReducer from './role'

const reducer = combineReducers({
  testReducer,
  appReducer,
  roleReducer,
})

export const rootReducer = (state: any, action: any) => {
  switch (action.type) {
    case CLEAR_REDUCER:
      return reducer(undefined, action)
    default:
      return reducer(state, action)
  }
}

export default rootReducer
