import React from 'react'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'

import UserMenu from '../UserMenu'

import type { IAuthenticatedHeaderMenuProps } from './types'
import { getAppDataFromStorage } from 'utils/app'

function AuthenticatedHeaderMenu({
  collapsed,
  toggle,
  isInnerOrganization,
  organizationName,
}: IAuthenticatedHeaderMenuProps): JSX.Element {
  return (
    <div className="authenticated-header-menu-container">
      <div className="left-component">
        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: 'trigger',
          onClick: toggle,
        })}
        {renderHeaderName()}
      </div>
      <div className="right-component">
        <UserMenu />
      </div>
    </div>
  )

  function renderHeaderName() {
    let name: string | undefined = ''

    if (isInnerOrganization) {
      name = organizationName
    } else {
      name = getAppDataFromStorage().app.name
    }

    return <strong className="organization-name">{name}</strong>
  }
}

export default AuthenticatedHeaderMenu
