import { useQuery } from '@apollo/client'

import { getUserTypeEndpoint } from 'utils/user'
import { appLocalAccessToken } from 'utils/localService'
import { onDefaultErrorMessage } from 'utils/apollo'

import QUERY_DOCUMENT from './getMyPermission'

import type { MyPermissionAPIPayload } from '.'
import type { APIPayloadResponse, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'

interface MyPermissionData {
  getMyPermission: APIPayloadResponse<MyPermissionAPIPayload[]>
}

const useGetMyPermission: GraphQLServiceQueryHook<MyPermissionData> = (options) => {
  return useQuery(QUERY_DOCUMENT, {
    context: {
      uri: getUserTypeEndpoint(appLocalAccessToken.get()),
    },
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useGetMyPermission
