import { gql } from '@apollo/client'

const MY_PERMISSION = gql`
  {
    getMyPermission {
      payload {
        permissionKey
        code
        scopeList
      }
    }
  }
`

export default MY_PERMISSION
