import { Role } from 'graphQL/schemaType/role'

export const SET_ROLE = 'SET_ROLE'

export interface RoleStateType {
  role: Role
}

export interface SetRoleActionType {
  type: typeof SET_ROLE
  payload: Role
}
