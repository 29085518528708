import { useMutation } from '@apollo/client'

import { onDefaultErrorMessage } from 'utils/apollo'

import MUTATION_DOCUMENT from './refreshAccessToken'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'
import type { RefreshAccessTokenAPIPayload } from './interface'

interface RefreshTokenData {
  refreshAccessToken: APIPayloadResponse<RefreshAccessTokenAPIPayload>
}

interface RefreshTokenVars {
  refreshToken: string
}

const useRefreshAccessToken: GraphQLServiceMutationHook<RefreshTokenData, RefreshTokenVars> = (options) => {
  return useMutation(MUTATION_DOCUMENT, {
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useRefreshAccessToken
