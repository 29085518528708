import { Space, Table, Dropdown, Button, Menu } from 'antd'
import { RedoOutlined, CheckCircleOutlined, SettingFilled } from '@ant-design/icons'
// import { generatePath, useHistory } from 'react-router'

// import { routePaths } from 'pages/routeConfig'

function SystemAdminApplication() {
  // const history = useHistory()
  // function goToAdministratorDetail() {
  //   history.push(generatePath(routePaths.systemAdminAdministratorDetail))
  // }
  const menu = (
    <Menu>
      <Menu.Item>
        <RedoOutlined />
        Sync App
      </Menu.Item>
      <Menu.Item>
        <RedoOutlined />
        Sync Config
      </Menu.Item>
      <Menu.Item>
        <SettingFilled />
        Manage
      </Menu.Item>
    </Menu>
  )
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_text: any, render: any) => (
        <>
          <img
            src="https://s3.hooray.site/thamturakit-public/hooray-account/1be3e437-8dee-4b8c-b97c-324a93679dde_logo.png"
            alt=""
            style={{ width: 40 }}
          />
          {'  '}
          {_text}
        </>
      ),
    },
    {
      title: 'Discription',
      dataIndex: 'discription',
      key: 'discription',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_text: any, render: any) => (
        <span key="status-render" className="service-status">
          <Space size={5} style={{ color: 'green', fontSize: '15px' }}>
            <CheckCircleOutlined />
            {render.status}
          </Space>
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_text: any, render: any) => (
        <span key="manage" className="service-action-link">
          <Dropdown overlay={menu} placement="bottomLeft">
            <Button type="ghost" shape="round">
              Action
            </Button>
          </Dropdown>
        </span>
      ),
    },
  ]

  const data = [
    {
      key: '1',
      name: 'Khomphet Meesub',
      discription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
      status: 'Active',
    },
    {
      key: '2',
      name: 'Jim Green',
      discription: ' Neque rerum quae, numquam error mollitia labore dolore. ',
      status: 'Active',
    },
  ]
  return <Table dataSource={data} columns={columns} />
}

export default SystemAdminApplication
