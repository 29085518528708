import React, { useState } from 'react'
import { Button, message, Space } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import ContentCard from 'components/Card/Content'
import SearchInput from 'components/Input/Search'
import PageTitle from 'components/PageTitle'
import { MemberDashboard } from 'components/Dashboard/Member'
import InvitationMemberModal from 'components/InvitationMemberModal'

import InnerOrganizationLayout from 'layouts/InnerOrganizationLayout'

import useInviteUserOrganization from 'graphQL/useInviteUserOrganization'

import { appLocalOrgKey } from 'utils/localService'
import { onDefaultErrorMessage } from 'utils/apollo'

import useGetMemberOrganization from 'graphQL/useGetMemberOrganization'
import useDeleteUserOrganization from 'graphQL/useDeleteUserOrganization'

const Member: React.FC = () => {
  const { t } = useTranslation()

  const [visibleInvitationMember, setVisibleInvitationMember] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const orgKey = appLocalOrgKey.get() || ''

  const memberOrganizationQuery = useGetMemberOrganization({
    variables: {
      orgKey,
      input: {
        search: {
          'email.value': searchValue,
        },
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  const [inviteUserOrganization, inviteUserOrganizationResp] = useInviteUserOrganization({
    onCompleted(resp) {
      message.success(
        t('invitationModal:inviteSuccess', {
          email: resp.inviteUserOrganization.payload.email,
        })
      )

      setVisibleInvitationMember(false)
    },
    onError(error) {
      onDefaultErrorMessage(error)

      setVisibleInvitationMember(false)
    },
  })
  const [deleteUserOrganization] = useDeleteUserOrganization({
    onCompleted() {
      memberOrganizationQuery.refetch()
    },
  })

  const onDeleteUserOrganization = (userId: string, orgKey: string) => {
    deleteUserOrganization({
      variables: {
        userId,
        orgKey,
      },
    })
  }

  return (
    <InnerOrganizationLayout>
      <div className="admin-content-container">
        <PageTitle
          title="Administrator"
          extra={[
            <Button type="primary" key="create" onClick={() => setVisibleInvitationMember(true)}>
              <Space size={12}>
                <PlusCircleOutlined /> Invite Member
              </Space>
            </Button>,
          ]}
        />
        <ContentCard
          loading={memberOrganizationQuery.loading}
          title="Administrator Management"
          rightComponent={<SearchInput onSearch={(value) => setSearchValue(value)} />}
        >
          {!memberOrganizationQuery.loading && (
            <MemberDashboard
              memberList={memberOrganizationQuery.data?.getMemberOrganization.payload}
              onDeleteUserOrganization={onDeleteUserOrganization}
            />
          )}
        </ContentCard>
      </div>

      <InvitationMemberModal
        visible={visibleInvitationMember}
        loading={inviteUserOrganizationResp.loading}
        onCancel={() => setVisibleInvitationMember(false)}
        onInviteMember={(emailList) => {
          inviteUserOrganization({
            variables: {
              emailList,
              orgKey,
            },
          })
        }}
      />
    </InnerOrganizationLayout>
  )
}

export default Member
