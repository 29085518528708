import { Checkbox } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { IPermissonData } from 'components/Table/AppRole'
import PermissionTable from 'components/Table/AppRole/Permission'
import useGetPermissionRole, { PermissionRoleAPIPayload } from 'graphQL/useGetPermissionRole/useGetPermissionRole'
import { useEffect, useState } from 'react'

interface propsType {
  roleKey: string
}
function PermissionService({ roleKey }: propsType): JSX.Element {
  const [permission, setPermission] = useState<PermissionRoleAPIPayload[] | any>()
  function onChange(checkedValues: any) {
    console.log('checked = ', checkedValues)
  }
  const { data } = useGetPermissionRole({
    variables: {
      roleKey: roleKey,
    },
  })
  useEffect(() => {
    const permissionList = data
    setPermission(permissionList?.getPermissionRole?.payload)
  }, [data])
  const options = [
    { label: 'Edit', value: 'EDIT' },
    { label: 'Read', value: 'READ' },
  ]
  const columns: ColumnsType<IPermissonData> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Key',
      dataIndex: 'permissionKey',
      key: 'permissionKey',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: (_text, render) => {
        return <Checkbox.Group options={options} value={render.code} onChange={onChange} />
      },
    },
  ]
  return (
    <>
      <PermissionTable dataSource={permission} columns={columns} rowKey={'permissionCoreService'} />
    </>
  )
}

export default PermissionService
