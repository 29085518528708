import { useState } from 'react'
import { Button, Space, Pagination } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import ContentCard from 'components/Card/Content'
import OrganizationField from 'components/Dashboard/Organization/OrganizationField'
import SearchInput from 'components/Input/Search'
import PageTitle from 'components/PageTitle'

import AuthenticatedLayout from 'layouts/AuthenticatedLayout'

import { GET_LOCALE_TEXT } from 'graphQL/schemas/organization'

import API from 'constants/api'

import FullScreenLoading from 'components/FullScreenLoading'

import { routePaths } from '../routeConfig'

import useGetOrganization from 'graphQL/useGetOrganization'
import { defaultPagination } from 'graphQL/config'

import type { Pagination as PaginationType } from 'graphQL/graphQL-service-hook'

function Organization(): JSX.Element {
  const { t } = useTranslation()

  const loading = false
  const history = useHistory()

  const [searchVal, setSearchVal] = useState('')
  const [pagination, setPagination] = useState<PaginationType | undefined>(defaultPagination)
  const [searchOrganization, setSearchOrganization] = useState({})

  useQuery(GET_LOCALE_TEXT, {
    variables: {
      input: {
        query: {
          dataKey: 'ORGANIZATION_NAME',
        },
        search: {
          text: searchVal,
        },
      },
    },
    fetchPolicy: 'network-only',
    context: {
      uri: API.BOOKING.ADMIN,
      headers: {
        credentialKey: '123456789',
      },
    },
    onCompleted(resp: any) {
      const orgKeyByName: any = []

      resp.getLocaleText.payload.forEach((element: any) => {
        if (!orgKeyByName.includes(element.orgKey)) orgKeyByName.push(element?.orgKey)
      })

      if (searchVal !== '') {
        setSearchOrganization({
          $or: [
            {
              name: {
                $regex: searchVal,
              },
            },
            {
              orgKey: {
                $in: orgKeyByName,
              },
            },
          ],
        })
        setPagination({
          ...pagination,
          page: defaultPagination.page,
        })
      } else {
        setSearchOrganization({})
      }
    },
  })

  const organizationQuery = useGetOrganization({
    skip: !searchOrganization,
    variables: {
      input: {
        query: searchOrganization,
        pagination: {
          limit: pagination?.limit,
          page: pagination?.page,
        },
        sort: {
          createdAt: -1,
        },
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted(resp) {
      setPagination(resp.getOrganization.pagination)
    },
  })

  const isLoading = organizationQuery.loading
  const organizationData = organizationQuery.data?.getOrganization

  const handleChangePage = (page: number, pageSize?: number | undefined) => {
    setPagination({
      ...pagination,
      page,
      limit: pageSize || defaultPagination.limit,
    })
  }

  const onShowSizeChange = (page: number, pageSize: number) => {
    setPagination({
      ...pagination,
      page,
      limit: pageSize,
    })
  }

  return (
    <AuthenticatedLayout>
      {loading ? (
        <FullScreenLoading />
      ) : (
        <div className="admin-content-container">
          <PageTitle
            isRootPage
            title={t('organization:page.home')}
            extra={[
              <Button type="primary" key="create" onClick={() => history.push(routePaths.organizationCreate)}>
                <Space size={12}>
                  <PlusCircleOutlined /> {t('organization:create')}
                </Space>
              </Button>,
            ]}
          />
          <ContentCard
            loading={isLoading}
            title={`${t('organization:contentTitle.dashboard')} `}
            rightComponent={
              <SearchInput
                onSearch={(value) => setSearchVal(value)}
                placeholder={t('organization:search:placeholder')}
              />
            }
          >
            {!isLoading && (
              <>
                <OrganizationField
                  organizations={organizationData?.payload}
                  allItemCount={pagination?.totalItems || 0}
                />
                <div>
                  <Pagination
                    className="admin-content-container-pagination"
                    defaultCurrent={1}
                    current={pagination?.page}
                    total={pagination?.totalItems}
                    defaultPageSize={pagination?.limit}
                    onChange={handleChangePage}
                    onShowSizeChange={onShowSizeChange}
                    pageSizeOptions={['5', '10', '20', '50', '100']}
                  />
                </div>
              </>
            )}
          </ContentCard>
        </div>
      )}
    </AuthenticatedLayout>
  )
}

export default Organization
