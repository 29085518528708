import { Button, Space, Table, TablePaginationConfig } from 'antd'
import dayjs from 'dayjs'
import { generatePath, useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import TotalDataSourceText from 'components/Table/TotalDataSourceText'
import TableColumnFormat from 'components/Table/ColumnFormat'
import ApprovalStatus from 'components/Status/ApprovalStatus'
import FontawesomeIcon from 'components/FontawesomeIcon'

import { useOrganizationContext } from 'contexts/OrganizationProvider'

import { routePaths } from 'pages/routeConfig'

import type { ColumnType } from 'antd/lib/table'
import type { OrganizationAPIPayload } from 'graphQL/schemaType/organization'
import type { OrganizationApprovalProps } from './types'

function OrganizationApprovalDashboard({
  dataSource,
  pagination,
  onPaginationChange,
}: OrganizationApprovalProps): JSX.Element {
  const { t } = useTranslation()
  const organization = useOrganizationContext()

  const history = useHistory()

  const organizationApprovalDashboard = 'approval:organization.contentCard'
  const organizationApprovalDashboardTable = `${organizationApprovalDashboard}.table`

  function onLinkToOrganizationDetail(orgKey: string) {
    organization.setOrgKey(orgKey)

    history.push(
      generatePath(routePaths.organizationDetail, {
        orgKey,
        slug: 'setting',
      })
    )
  }

  const columns: ColumnType<OrganizationAPIPayload>[] = [
    {
      key: 'name',
      title: () => (
        <TableColumnFormat
          title={t(`${organizationApprovalDashboardTable}.name.title`)}
          subtitle={t(`${organizationApprovalDashboardTable}.name.subtitle`)}
        />
      ),
      dataIndex: 'name',
    },
    {
      key: 'createdAt',
      title: () => (
        <TableColumnFormat
          title={t(`${organizationApprovalDashboardTable}.createdAt.title`)}
          subtitle={t(`${organizationApprovalDashboardTable}.createdAt.subtitle`)}
        />
      ),
      sorter: (a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
      render: (_text, render) => <span>{dayjs(render.createdAt).format('DD MMM YYYY')}</span>,
    },
    {
      key: 'updatedAt',
      title: () => (
        <TableColumnFormat
          title={t(`${organizationApprovalDashboardTable}.updatedAt.title`)}
          subtitle={t(`${organizationApprovalDashboardTable}.updatedAt.subtitle`)}
        />
      ),
      sorter: (a, b) => dayjs(a.updatedAt).unix() - dayjs(b.updatedAt).unix(),
      render: (_text, render) => <span>{dayjs(render.updatedAt).format('DD MMM YYYY')}</span>,
    },
    {
      key: 'status',
      title: () => (
        <TableColumnFormat
          title={t(`${organizationApprovalDashboardTable}.status.title`)}
          subtitle={t(`${organizationApprovalDashboardTable}.status.subtitle`)}
        />
      ),
      render: (_text, render) => (
        <Space size={12}>
          <ApprovalStatus key="status" status={render.status} />
        </Space>
      ),
    },
    {
      key: 'action',
      render: (_text, render) => (
        <Button
          type="ghost"
          onClick={() => onLinkToOrganizationDetail(render.orgKey)}
          icon={<FontawesomeIcon iconName="chevron-right" style={{ marginRight: '1rem' }} />}
        >
          {t(`${organizationApprovalDashboard}.action.goToOrganization`)}
        </Button>
      ),
    },
  ]

  const paginationOptions: TablePaginationConfig = {
    current: pagination?.page,
    pageSize: pagination?.limit,
    total: pagination?.totalItems,
    onChange: onPaginationChange,
  }

  return (
    <>
      <Table dataSource={dataSource} columns={columns} rowKey="orgKey" pagination={paginationOptions} />
      <TotalDataSourceText total={dataSource?.length} />
    </>
  )
}

export default OrganizationApprovalDashboard
