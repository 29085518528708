import { ConfigProvider } from 'antd'
import { Provider } from 'react-redux'
import store from 'redux/store'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import ApolloContainer from 'containers/ApolloContainer'
import PermissionProvider from 'contexts/PermissionProvider'
import AuthProvider from 'contexts/AuthProvider'
import ThemeProvider from 'contexts/ThemeProvider'
import OrganizationProvider from 'contexts/OrganizationProvider'

import PageRouter from 'pages/PageRouter'

import setupAppLocal, { appLocalLocale } from 'utils/localService/app'
import setupI18n from 'i18n'

import thTH from 'antd/lib/locale/th_TH'
import enUS from 'antd/lib/locale/en_US'

import 'antd/dist/antd.css'
import 'styles/style.scss'

dayjs.extend(relativeTime)

setupAppLocal()
setupI18n()

export default function AppContainer(): JSX.Element {
  return (
    <Provider store={store}>
      <ConfigProvider locale={appLocalLocale.get() === 'en' ? enUS : thTH} componentSize="large">
        <AuthProvider>
          <ApolloContainer>
            <PermissionProvider>
              <ThemeProvider>
                <OrganizationProvider>
                  <PageRouter />
                </OrganizationProvider>
              </ThemeProvider>
            </PermissionProvider>
          </ApolloContainer>
        </AuthProvider>
      </ConfigProvider>
    </Provider>
  )
}
