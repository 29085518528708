// import { sortMenu } from 'utils/sortMenu'
import { SET_ROLE, RoleStateType, SetRoleActionType } from './types'

const initialStateGetTest: RoleStateType = {
  role: {
    name: '',
    attribute: '',
    userField: '',
    regisable: '',
    roleType: '',
    roleKey: '',
    status: '',
    isDefault: '',
    isReplaceable: '',
  },
}

const appReducer = (state = initialStateGetTest, action: SetRoleActionType): RoleStateType => {
  switch (action.type) {
    case SET_ROLE:
      console.log(action.payload)
      return {
        ...state,
        role: action.payload,
      }
    default:
      return state
  }
}

export default appReducer
