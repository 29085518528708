import { Tabs } from 'antd'
import store from 'redux/store'
import PermissionService from './PermissionService'

const { TabPane } = Tabs
function PermissionPage(): JSX.Element {
  const role = store.getState().roleReducer?.role || undefined
  function callback(key: any) {
    console.log(key)
  }
  return (
    <>
      <Tabs onChange={callback} type="card">
        <TabPane tab="Core Service" key="1">
          <PermissionService roleKey={role.roleKey} />
        </TabPane>
        {/* <TabPane tab="CMS Service" key="2">
          <PermissionService />
        </TabPane> */}
      </Tabs>
    </>
  )
}

export default PermissionPage
