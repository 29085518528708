import { BrowserRouter, Route, Switch, Redirect, generatePath, RouteProps } from 'react-router-dom'

import withAuth from 'middlewares/withAuth'

import { appLocalAccessToken } from 'utils/localService'
import { decodeToken } from 'utils/token'

import { nonRequiredAuthenticatedRoutes, privateRoutes, routePaths, systemAdminRoutePaths } from './routeConfig'

import type { ITokenPayload } from 'utils/token/interface'

interface PrivateRouteProps extends RouteProps {
  isLoggedIn?: boolean
  isSystemAdmin?: boolean
}

const PageRouter: React.FC = () => {
  const accessToken = appLocalAccessToken.get()

  const user = decodeToken<ITokenPayload>(accessToken)

  const isSystemAdmin = user?.userType === 'SYSTEM_ADMIN'

  return (
    <BrowserRouter>
      <Switch>
        {nonRequiredAuthenticatedRoutes.map((route) => (
          <Route key={route.path} exact path={route.path} component={route.component} />
        ))}

        {privateRoutes.map((route) => (
          <PrivateRoute
            key={route.path}
            path={route.path}
            component={route.component}
            exact
            isSystemAdmin={isSystemAdmin}
          />
        ))}

        {systemAdminRoutePaths.map((route) => (
          <SystemAdminPrivateRoute
            key={route.path}
            path={route.path}
            component={route.component}
            exact
            isSystemAdmin={isSystemAdmin}
          />
        ))}
        {/* {systemAdminRoutePaths.map((route) => (
          <Route exact {...route} />
        ))} */}

        <PrivateRoute
          path="/"
          render={() => <Redirect to={routePaths.organization} />}
          exact
          isSystemAdmin={isSystemAdmin}
        />

        <Redirect
          from="/approval"
          to={generatePath(routePaths.approvalDetail, {
            page: 'organization',
          })}
        />
      </Switch>
    </BrowserRouter>
  )
}

export default PageRouter

const PrivateRoute: React.FC<PrivateRouteProps> = withAuth(({ isSystemAdmin, ...props }) => {
  if (isSystemAdmin) {
    return (
      <Redirect
        to={generatePath(routePaths.systemAdmin, {
          page: 'dashboard',
        })}
      />
    )
  }

  return <Route {...props} />
})

const SystemAdminPrivateRoute: React.FC<PrivateRouteProps> = withAuth(({ isSystemAdmin, ...props }) => {
  if (!isSystemAdmin) {
    return <Redirect to={routePaths.organization} />
  }

  return <Route {...props} />
})
