import ContentCard from 'components/Card/Content'
// import { NewAdminDetails } from 'components/Form/Administrator'
// import NewSystemAdministratorForm from 'components/Form/Administrator/NewSystemAdministratorForm'
import { ApplocationForm } from 'components/Form/Application'
import PageTitle from 'components/PageTitle'
import SystemAdminLayout from 'layouts/SystemAdminLayout'
import { useHistory } from 'react-router'

function NewApplication(): JSX.Element {
  const history = useHistory()

  function onBack() {
    history.goBack()
  }

  function onFinish(values: any) {
    console.log(values)
  }
  return (
    <SystemAdminLayout>
      <div className="admin-content-container">
        <>
          <PageTitle title="New Application" onBack={onBack} />
          <ContentCard title="New Application">
            <ApplocationForm onFinish={onFinish} />
          </ContentCard>
        </>
      </div>
    </SystemAdminLayout>
  )
}

export default NewApplication
