import { useQuery } from '@apollo/client'

import GET_PERMISSION_ROLE from './getPermissionRole'

import { onDefaultErrorMessage } from 'utils/apollo'

import type { APIPayloadResponse, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'
export interface PermissionRoleAPIPayload {
  name: string
  permissionKey: string
  code: [string]
}
interface PermissionRoleData {
  getPermissionRole: APIPayloadResponse<PermissionRoleAPIPayload>
}

interface PermissionRoleVars {
  roleKey: string
}

const useGetPermissionRole: GraphQLServiceQueryHook<PermissionRoleData, PermissionRoleVars> = (options) => {
  return useQuery(GET_PERMISSION_ROLE, {
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useGetPermissionRole
