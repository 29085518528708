import { useContext } from 'react'
import { Layout } from 'antd'

import { AppRole } from 'components/Sidebar/AppRole'

import { ThemeContext } from 'contexts/ThemeProvider/ThemeProvider'

import type { ILayoutProps } from '../types'

const { Sider, Content } = Layout

function AppRoleLayout({ children }: ILayoutProps): JSX.Element {
  const theme = useContext(ThemeContext)
  return (
    <Layout style={{ minHeight: 'calc(100vh-64px)', borderLeft: '2px solid #eee' }} className="authenticated-layout">
      <Sider
        trigger={null}
        collapsible
        collapsed={!theme.collapsed}
        theme="light"
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          // left: 0,
        }}
      >
        <AppRole />
      </Sider>
      <Layout style={{ marginLeft: !theme.collapsed ? 80 : 200 }}>
        <Content>
          <div className="authenticated-content-container">{children}</div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default AppRoleLayout
