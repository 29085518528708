import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import OrganizationDashboard from 'components/Dashboard/Organization'

import { GET_ORG_FIELD } from 'graphQL/schemas/organization'

import apolloClient from 'constants/initializeApolloClient'

import { RootState } from 'redux/store'

import { IServiceMenu } from 'hooks/useServiceMenuQuery'

import type { IOrganizationProps } from './types'

const OrganizationField: React.FC<IOrganizationProps> = ({ organizations, allItemCount }) => {
  const orgKeys: string[] | undefined = organizations?.map((e: { orgKey: string }): string => e.orgKey)

  const serviceMenus: IServiceMenu[] = useSelector((state: RootState) => state.appReducer.serviceMenus)

  const [orgField, setOrgField] = useState([])
  const [isFetchCompleted, setFetchCompleted] = useState(false)

  useEffect(() => {
    async function loopQuery() {
      Promise.all(
        serviceMenus.map(async (serviceMenu: IServiceMenu) => {
          try {
            const query = await apolloClient.query({
              query: GET_ORG_FIELD,
              variables: {
                orgKeyList: orgKeys,
              },
              fetchPolicy: 'network-only',
              context: {
                uri: `${serviceMenu.service.endpoint.gql.resourceAdmin}`,
              },
            })
            return query.data.getOrgField.payload
          } catch (e) {
            return null
          }
        })
      ).then((res) => {
        const orgFieldArray: any = []
        res.forEach((element: any[]) => {
          if (element) orgFieldArray.push(...element)
        })

        setOrgField(orgFieldArray)
        setFetchCompleted(true)
      })
    }

    if (orgKeys && orgKeys.length > 0 && !isFetchCompleted) {
      loopQuery()
    }
  }, [orgKeys, serviceMenus, isFetchCompleted])

  if (!organizations) {
    return null
  }

  return <OrganizationDashboard organizations={organizations} orgField={orgField} allItemCount={allItemCount} />
}

export default OrganizationField
