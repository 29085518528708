import { useEffect, useState } from 'react'

import { usePermissionContext } from 'contexts/PermissionProvider'

const usePermission = (permissionKeys: string | string[]) => {
  const [canAccess, setCanAccess] = useState(false)

  const permission = usePermissionContext()

  useEffect(() => {
    if (Array.isArray(permissionKeys)) {
      const checkMultiplePermissions = permissionKeys.map((permissionKey) => {
        return permission.myPermissions.find((permission) => permission.permissionKey === permissionKey)
      })

      setCanAccess(checkMultiplePermissions.every((isAccess) => isAccess))
    } else {
      setCanAccess(!!permission.myPermissions.find((permission) => permission.permissionKey === permissionKeys))
    }
  }, [permission.myPermissions, permissionKeys])

  return {
    canAccess,
    myPermissions: permission.myPermissions,
    permissionKeys,
  }
}

export default usePermission
