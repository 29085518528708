import React from 'react'
import { Form, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'

import { useState, useEffect } from 'react'

import InnerOrganizationLayout from 'layouts/InnerOrganizationLayout'

import ContentCard from 'components/Card/Content'
import PageTitle from 'components/PageTitle'
import AlertActivationOrganize from 'components/AlertActivationOrganize'

import {
  GET_ACCOUNT_DATA,
  GET_MASTERDATA,
  GET_ORG_APPROVAL,
  GET_S3GETOBJECT,
  UPDATE_ORGANIZATION,
  // UPDATE_ORG_APPROVAL,
  UPDATE_ORG_STATUS,
} from 'graphQL/schemas/organization'

import usePermission from 'hooks/usePermission'

import VerifyAccountForm from './VerifyAccountForm'
import PendingAccountForm from './PendingAccountForm'

import type { AccountStatus, IOrganizationAPIPayload } from './types'
import type { OrganizationStatus } from 'graphQL/schemaType/organization'

function AccountForm(): JSX.Element {
  const { t, i18n } = useTranslation()
  const locale = i18n.language === 'enUS' ? 'en' : 'th'
  const { orgKey } = useParams<any>()

  const status: AccountStatus = 'VERIFIED'
  const organization: IOrganizationAPIPayload = {} as IOrganizationAPIPayload
  const [attachFiles, setAttachFiles] = useState<any[]>([])
  const [countryCodeData, setCountryCodeData] = useState<any>([])
  const [contact, setContact] = useState<any>([])
  const [countryCode, setCountryCode] = useState({
    code: '',
  })
  const [language, setLaguage] = useState({
    language: '',
  })
  const [values, setValues] = useState({
    contact: '',
    phone: '',
    email: '',
    status: '',
    organization: '',
  })

  const permission = usePermission('ALL_ORG_MANAGEMENT')

  useQuery(GET_MASTERDATA, {
    variables: {
      filter: {
        parentKey: 'LANGUAGE',
        locale,
      },
    },
    onCompleted(resp) {
      setContact(resp.getMasterData?.payload)
    },
  })

  useQuery(GET_MASTERDATA, {
    variables: {
      filter: {
        parentKey: 'DIALING_CODE',
        locale,
      },
    },
    onCompleted(resp) {
      setCountryCodeData(resp.getMasterData?.payload)
    },
  })

  const dataAccount = useQuery(GET_ACCOUNT_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        orgKey: orgKey,
      },
    },
    onCompleted(resp) {},
  })

  const attachFile = useQuery(GET_ORG_APPROVAL, {
    variables: {
      filter: {
        orgKey: orgKey,
      },
    },
    onCompleted() {
      signedUrl.refetch()
    },
  })

  const signedUrl = useQuery(GET_S3GETOBJECT, {
    skip: attachFile.loading && !attachFile.data,
    variables: {
      fileKeys: attachFile.data?.getOrgApprovalAttribute.payload[0]?.approvalAttribute.attachFileList.map(
        (fileKey: any) => fileKey
      ),
    },
    onCompleted(resp) {
      const urlKey = resp.getS3GetObjectSignedUrl.payload.map((key: any) => ({
        fileKey: key.fileKey,
        signedUrl: key.signedUrl,
        url: key.signedUrl,
      }))
      setAttachFiles(urlKey)
    },
  })

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)

  const [updateOrgStatus] = useMutation(UPDATE_ORG_STATUS)

  function onFinish(value: any) {
    updateOrganization({
      variables: {
        orgKey: orgKey,
        input: {
          contactName: value.contactName,
          name: value.organizationName,
          attribute: {
            phoneNumber: {
              countryCode: value.prefix,
              number: value.phone,
            },
            contactLanguage: value.language,
          },
        },
      },
    })
      .then(() => {
        updateOrgStatus({
          variables: {
            orgKey: orgKey,
            // eslint-disable-next-line no-constant-condition
            status: values.status === 'PREPARING' || 'NEED_MORE_INFORMATION' ? 'REVIEWING' : values.status,
          },
        })
          .then(() => {
            dataAccount.refetch()
            message.success('Organization Update Success.')
          })
          .catch(() => {
            message.error('Error while update an organization. Please try again.')
          })
      })
      .catch(() => {
        message.error('Error while update an organization. Please try again.')
      })
  }
  const cancelRequest = () => {
    updateOrgStatus({
      variables: {
        orgKey: orgKey,
        status: 'PREPARING',
      },
    })
      .then(() => {
        dataAccount.refetch()
        message.success('Organization Cancel Request.')
      })
      .catch(() => {
        message.error('Error while update an organization. Please try again.')
      })
  }
  useEffect(() => {
    if (dataAccount?.data?.getOrganization?.payload?.length > 0) {
      setValues({
        phone: dataAccount?.data.getOrganization.payload[0]?.attribute?.phoneNumber?.number,
        contact: dataAccount?.data.getOrganization.payload[0]?.contactName,
        email: dataAccount?.data.getOrganization.payload[0]?.contactEmailList,
        status: dataAccount?.data.getOrganization.payload[0]?.status,
        organization: dataAccount?.data.getOrganization.payload[0]?.name,
      })
      setLaguage({ language: dataAccount?.data.getOrganization.payload[0].attribute?.contactLanguage })
      setCountryCode({ code: dataAccount?.data.getOrganization.payload[0].attribute?.phoneNumber?.countryCode })

      if (!dataAccount?.data.getOrganization.payload[0]?.attribute?.phoneNumber) {
        setCountryCode({ code: 'DIALING_CODE.THAILAND_66' })
      }

      if (!dataAccount?.data.getOrganization.payload[0].attribute?.contactLanguage) {
        setLaguage({ language: 'LANGUAGE.TH' })
      }
    }
  }, [dataAccount?.data])
  // console.log('permissionContext',permissionContext)
  return (
    <InnerOrganizationLayout>
      <div className="admin-content-container">
        {permission.canAccess && <AlertActivationOrganize status={values.status as OrganizationStatus} />}
        <PageTitle title={t('organization:account.head')} />
        <ContentCard title={t('organization:account.preview')}>
          <Form
            name="accountSettingForm"
            labelAlign="left"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
            requiredMark={false}
            onFinish={onFinish}
            fields={[
              { name: ['prefix'], value: countryCode.code },
              { name: ['phone'], value: values.phone },
              { name: ['language'], value: language.language },
            ]}
          >
            {status === 'VERIFIED' ? (
              <VerifyAccountForm
                onFinish={onFinish}
                setLanguage={setLaguage}
                attachFiles={attachFiles}
                setAttachFiles={setAttachFiles}
                values={values}
                setValues={setValues}
                countryCodeData={countryCodeData}
                setCountryCode={setCountryCode}
                contact={contact}
                cancelRequest={cancelRequest}
              />
            ) : (
              <PendingAccountForm organization={organization} />
            )}
          </Form>
        </ContentCard>
      </div>
    </InnerOrganizationLayout>
  )
}

export default AccountForm
