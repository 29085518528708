import { Menu } from 'antd'
import { Link, useLocation } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import FontawesomeIcon from 'components/FontawesomeIcon'
import { ServiceOrganizationSidebar } from '.'

import useQueryParams from 'hooks/useQueryParams'
import usePermission from 'hooks/usePermission'

import { sortMenu } from 'utils/sortMenu'

import { AppStateType } from 'redux/application/types'
import { RootState } from 'redux/store'

import type { IMenuListConfig, IRoute } from '../types'

const route: IRoute = {
  '/': '0',
  '/organization': '0',
  '/organization/create': '0',
  '/organization/setting': '0',
  '/app-role': '1',
  '/app-role/role-setting': '1',
  '/app-role/user-field': '1',
  '/app-role/security-role': '1',
  '/app-role/permission': '1',
  '/approval': '2',
  '/approval/user': '3',
  '/approval/organization': '4',
  '/profile-setting': '5',
}

function Organization(): JSX.Element {
  const application: AppStateType = useSelector((state: RootState) => state.appReducer)

  const { t } = useTranslation()
  const location = useLocation()

  const serviceKey = useQueryParams('serviceKey')

  const permission = usePermission('ALL_ORG_MANAGEMENT')

  const { pathname } = location

  const appMenus = application.appMenus.find((appMenu) => appMenu.service.serviceKey === serviceKey)
  const findAppByPathname = appMenus?.menus.find((appMenu) => pathname === appMenu.linkTo)

  const currentRoute = findAppByPathname ? findAppByPathname.key : route[pathname]

  const organizationMenuList: IMenuListConfig[] = permission.canAccess
    ? [
        {
          key: route['/organization'],
          title: t('sidebar:organization'),
          linkTo: '/organization',
          iconName: 'columns',
        },
        {
          key: route['/app-role'],
          title: t('sidebar:appRole'),
          linkTo: '/app-role',
          iconName: 'users-cog',
        },
        {
          key: route['/approval'],
          title: t('sidebar:approval'),
          linkTo: '',
          iconName: 'user-check',
          subKey: 'approvalSubMenu',
          subMenus: [
            {
              key: route['/approval/organization'],
              title: t('sidebar:organization'),
              linkTo: '/approval/organization',
            },
          ],
        },
      ]
    : [
        {
          key: route['/organization'],
          title: t('sidebar:organization'),
          linkTo: '/organization',
          iconName: 'columns',
        },
      ]
  return (
    <Menu
      theme="light"
      className="organization-menu-container"
      mode="inline"
      defaultSelectedKeys={['0']}
      defaultOpenKeys={['approvalSubMenu']}
      selectedKeys={[currentRoute]}
    >
      {organizationMenuList.map((menu) => {
        if (menu.subMenus && menu.subMenus.length > 0) {
          return (
            <Menu.SubMenu key={menu.subKey} icon={<FontawesomeIcon iconName={menu.iconName} />} title={menu.title}>
              {menu.subMenus.map((subMenu) => (
                <Menu.Item key={subMenu.key}>
                  <span>{subMenu.title}</span>
                  <Link to={subMenu.linkTo} />
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          )
        }

        return (
          <Menu.Item key={menu.key} icon={<FontawesomeIcon iconName={menu.iconName} />}>
            <span>{menu.title}</span>
            <Link to={menu.linkTo} />
          </Menu.Item>
        )
      })}
      {sortMenu(application?.appMenus).map((appMenu, index) => (
        <ServiceOrganizationSidebar
          key={appMenu.service.serviceKey + index}
          menus={appMenu.menus}
          service={appMenu.service}
          serviceKey={appMenu.service.serviceKey}
          currentRoute={currentRoute}
        />
      ))}
    </Menu>
  )
}

export default Organization
