import React from 'react'
import { Avatar, Dropdown, Menu, Space, Typography } from 'antd'
import { DownOutlined, UserOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import styled, { createGlobalStyle } from 'styled-components'
import { useHistory } from 'react-router'

import { useAuthContext } from 'contexts/AuthProvider'

import { routePaths } from 'pages/routeConfig'

import FontawesomeIcon from 'components/FontawesomeIcon'
import USER_ACCOUNT_URL from 'constants/application'

const UserMenuDropdown: React.FC = () => {
  const { t } = useTranslation('userMenu')
  const history = useHistory()

  const auth = useAuthContext()

  return (
    <>
      <GlobalStyle />

      <Dropdown overlayClassName="user-dropdown-menu" overlay={renderMemu()} trigger={['click']}>
        <a href="#open-user-menu" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <Space size={8}>
            <Avatar icon={<UserOutlined />} />
            <Space size={2}>
              <EmailDropdownTitle ellipsis>{auth.email}</EmailDropdownTitle>
              <DownOutlined />
            </Space>
          </Space>
        </a>
      </Dropdown>
    </>
  )

  function renderMemu() {
    return (
      <Menu>
        <Menu.Item
          key="profile-management"
          onClick={() => window.open(USER_ACCOUNT_URL)}
          icon={<FontawesomeIcon iconName="user" style={{ marginRight: 4 }} />}
        >
          {t('button:profile')}
        </Menu.Item>
        <Menu.Item
          key="logout-menu"
          onClick={() => history.push(routePaths.signOut)}
          icon={<FontawesomeIcon iconName="sign-out-alt" style={{ marginRight: 4 }} />}
        >
          {t('button:logout')}
        </Menu.Item>
      </Menu>
    )
  }
}

export default UserMenuDropdown

const GlobalStyle = createGlobalStyle`
  .user-dropdown-menu {
    top: 50px !important;
    > .ant-dropdown-menu {
      margin-top: 8px;
    }
  }
`

const EmailDropdownTitle = styled(Typography.Text)`
  width: 150px;
  text-align: right;
  color: var(--primary-color);
`
