import { gql } from '@apollo/client'

const APP_BY_CREDENTIAL = gql`
  query getAppByCredential($credentialKey: String!) {
    getAppByCredential(credentialKey: $credentialKey) {
      payload {
        app {
          name
          appKey
          featureList {
            name
            featureKey
            enable
            config
            requiredFeatureKeyList
          }
          configurationList {
            configName
            configKey
            value {
              publicEndpoint
              bucket
              serviceEndpoint
            }
          }
        }
        credential {
          credentialKey
        }
      }
    }
  }
`

export default APP_BY_CREDENTIAL
