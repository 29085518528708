import React, { useContext, useState } from 'react'

import { MyPermissionAPIPayload } from 'graphQL/useGetMyPermission'

interface PermissionContextData {
  myPermissions: MyPermissionAPIPayload[]
  setPermissions: (permissions: MyPermissionAPIPayload[]) => void
}

const defaultPermissionContext: PermissionContextData = {
  myPermissions: [],
  setPermissions() {},
}

const PermissionContext = React.createContext<PermissionContextData>(defaultPermissionContext)

const PermissionProvider: React.FC = ({ children }) => {
  const [myPermissions, setMyPermissions] = useState<MyPermissionAPIPayload[]>([])

  return (
    <PermissionContext.Provider
      value={{
        myPermissions,
        setPermissions: (permissions) => {
          setMyPermissions(permissions)
        },
      }}
    >
      {children}
    </PermissionContext.Provider>
  )
}

export default PermissionProvider

export const usePermissionContext = () => useContext(PermissionContext)
