import { useQuery } from '@apollo/client'

import { onDefaultErrorMessage } from 'utils/apollo'

import MUTATION_DOCUMENT from './generateOrgTokenRef'

import type { APIPayloadResponse, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'

interface Organization {
  name: string
  orgKey: string
}

export interface GenerateOrgTokenRefAPIPayload {
  ref: string
  organization: Organization
}
interface GenerateOrgTokenRefData {
  generateOrgTokenRef: APIPayloadResponse<GenerateOrgTokenRefAPIPayload>
}

interface GenerateOrgTokenRefVars {
  orgKey: string
}

const useGenerateOrgTokenRef: GraphQLServiceQueryHook<GenerateOrgTokenRefData, GenerateOrgTokenRefVars> = (options) => {
  return useQuery(MUTATION_DOCUMENT, {
    onError(error) {
      onDefaultErrorMessage(error)
    },
    ...options,
  })
}

export default useGenerateOrgTokenRef
