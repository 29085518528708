import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ContentCard from 'components/Card/Content'
import SearchInput from 'components/Input/Search'
import PageTitle from 'components/PageTitle'
import { OrganizationApprovalDashboard } from 'components/Dashboard/Approval'

import api, { defaultPagination } from 'constants/api'

import useGetOrganization from 'graphQL/useGetOrganization'

import type { IPagination } from 'graphQL/types'

function OrganizationApproval(): JSX.Element {
  const [pagination, setPagination] = useState<IPagination>(defaultPagination)
  const [searchValue, setSearchValue] = useState('')

  const { t } = useTranslation()

  const organizationApproval = 'approval.organization'
  const organizationApprovalContentCard = `${organizationApproval}.contentCard`

  const organizationQuery = useGetOrganization({
    variables: {
      input: {
        search: {
          name: searchValue,
        },
        pagination: {
          limit: pagination.limit,
          page: pagination.page,
        },
        sort: {
          createdAt: -1,
        },
        query: {
          status: { $ne: 'PREPARING' },
        },
      },
    },
    context: {
      uri: api.CORE.ADMIN,
    },
    fetchPolicy: 'network-only',
  })

  const isLoading = organizationQuery.loading
  const organizationData = organizationQuery.data?.getOrganization

  function onOrganizationApprovalSearch(value: string) {
    setSearchValue(value)
  }

  function onPaginationChange(page: number, pageSize?: number | undefined) {
    setPagination({ page, limit: pageSize || pagination.limit })
  }

  return (
    <>
      <PageTitle title="Organization Approval" />
      <ContentCard
        loading={isLoading}
        title="Organization Approval"
        rightComponent={
          <SearchInput
            placeholder={t(`${organizationApprovalContentCard}:search:placeholder`)}
            onSearch={onOrganizationApprovalSearch}
          />
        }
      >
        {!isLoading && organizationData && (
          <OrganizationApprovalDashboard
            dataSource={organizationData.payload}
            pagination={organizationData.pagination}
            onPaginationChange={onPaginationChange}
          />
        )}
      </ContentCard>
    </>
  )
}

export default OrganizationApproval
