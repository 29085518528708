import { Menu } from 'antd'
import FontawesomeIcon from 'components/FontawesomeIcon'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import type { IMenuListConfig, IRoute } from '../types'

const route: IRoute = {
  '/app-role/role-setting': '0',
  '/app-role/user-field': '1',
  '/app-role/security-role': '2',
  '/app-role/permission': '3',
}

function AppRole(): JSX.Element {
  const location = useLocation()

  const { pathname } = location

  const currentRoute = route[pathname]

  const overviewMenuList: IMenuListConfig[] = [
    {
      key: '0',
      iconName: 'cog',
      linkTo: '/app-role/role-setting',
      title: 'Role setting',
    },
    {
      key: '1',
      iconName: 'file-alt',
      linkTo: '/app-role/user-field',
      title: 'User field',
    },
    {
      key: '2',
      iconName: 'shield-alt',
      linkTo: '/app-role/security-role',
      title: 'Security role',
    },
    {
      key: '3',
      iconName: 'lock',
      linkTo: '/app-role/permission',
      title: 'Permission',
    },
  ]
  return (
    <Menu
      theme="light"
      className="organization-menu-container"
      mode="inline"
      defaultSelectedKeys={['0']}
      selectedKeys={[currentRoute]}
    >
      <Menu.Divider />
      {overviewMenuList.map((menu) => (
        <Menu.Item key={menu.key} icon={<FontawesomeIcon iconName={menu.iconName} />}>
          <span>{menu.title}</span>
          <Link to={menu.linkTo} />
        </Menu.Item>
      ))}
    </Menu>
  )
}

export default AppRole
