import { Role } from 'graphQL/schemaType/role'
import { AppDispatch } from '../store'
import { SET_ROLE } from './types'

export const setRoleAction =
  (role: Role): any =>
  (dispatch: AppDispatch) =>
    dispatch({
      type: SET_ROLE,
      payload: role,
    })
