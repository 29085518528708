import SystemAdminApplication from 'components/Dashboard/Application/SystemAdminApplication'
// import ContentCard from 'components/Card/Content'
function Application(): JSX.Element {
  return (
    <>
      <SystemAdminApplication />
    </>
  )
}

export default Application
