import { gql } from '@apollo/client'
import { PERMISSION } from 'graphQL/schemas/role'

const GET_PERMISSION_ROLE = gql`
  query getPermissionRole($roleKey: String!){
    getPermissionRole(roleKey: $roleKey) {
      message
      code
      payload {${PERMISSION}}
    }
  }
`

export default GET_PERMISSION_ROLE
