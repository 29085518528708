import { useState } from 'react'
import { useParams } from 'react-router'
import { Button, Space } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

import ContentCard from 'components/Card/Content'
import PageTitle from 'components/PageTitle'
import AppRoleLayout from 'layouts/AppRole'
import AuthenticatedLayout from 'layouts/AuthenticatedLayout'
import RoleSettingPage from './RoleSettingPage'
import DataSecurityRolePage from './DataSecurityRolePage'
import PermissionPage from './PermissionPage'
import UserFieldPage from './UserFieldPage'
import UserFieldModel from 'components/Modal/AppRole/UserField'

import type { PageKey, AppRoleParamsPage } from './types'
function AppRoleSetting(): JSX.Element {
  const [isShowDialogUserField, setIsShowDialogUserField] = useState(false)
  const onCancelUserField = () => {
    setIsShowDialogUserField(false)
  }
  const params = useParams<AppRoleParamsPage>()
  function renderPanelByPageKey(pageKey: PageKey) {
    switch (pageKey) {
      case 'user-field':
        return (
          <>
            <PageTitle title="" />
            <UserFieldModel onCancel={onCancelUserField} isShowDialog={isShowDialogUserField} refetchRole={() => {}} />
            <ContentCard
              title="User Field"
              rightComponent={
                <Button
                  type="primary"
                  key="create"
                  onClick={() => {
                    setIsShowDialogUserField(true)
                  }}
                >
                  <Space size={12}>
                    <PlusCircleOutlined />
                    {/* {t('appRole:create')} */} Add Field
                  </Space>
                </Button>
              }
            >
              <UserFieldPage />
            </ContentCard>
          </>
        )
      case 'security-role':
        return (
          <>
            <PageTitle title="" />
            <ContentCard title="Data Security Role">
              <DataSecurityRolePage />
            </ContentCard>
          </>
        )
      case 'permission':
        return (
          <>
            <PageTitle title="" />
            <ContentCard title="Permission">
              <PermissionPage />
            </ContentCard>
          </>
        )
      default:
      case 'role-setting':
        return (
          <>
            <PageTitle title={''} />
            <ContentCard title="Role Setting">
              <RoleSettingPage />
            </ContentCard>
          </>
        )
    }
  }

  return (
    <AuthenticatedLayout>
      <AppRoleLayout>
        <div className="admin-content-container">{renderPanelByPageKey(params.page)}</div>
      </AppRoleLayout>
    </AuthenticatedLayout>
  )
}

export default AppRoleSetting
