import { Tabs, Typography } from 'antd'
import DataSecurityService from './DataSecurityService'

import imgNone from 'assets/images/data_security/none.png'
import imgOrganization from 'assets/images/data_security/organization.png'
import imgParent from 'assets/images/data_security/parent.png'
import imgSystem from 'assets/images/data_security/system.png'
import imgUser from 'assets/images/data_security/user.png'
import store from 'redux/store'

const { Text } = Typography
const { TabPane } = Tabs

const imgList = [
  { img: imgNone, text: 'None selected' },
  { img: imgUser, text: 'User' },
  { img: imgOrganization, text: 'Organization' },
  { img: imgParent, text: 'Parent:Child (Org)' },
  { img: imgSystem, text: 'System' },
]
const dataSecurityTypeList = (): JSX.Element => {
  return (
    <div style={{ textAlign: 'right' }}>
      {imgList.map((ojb: any) => {
        return (
          <>
            <img src={ojb.img} alt="" style={{ verticalAlign: 'middle', marginLeft: 15 }}></img>
            <Text type="secondary" style={{ marginLeft: 10 }}>
              {ojb.text}
            </Text>
          </>
        )
      })}
    </div>
  )
}
function DataSecurityRolePage(): JSX.Element {
  const role = store.getState().roleReducer?.role || undefined
  function callback(key: any) {
    console.log(key)
  }

  return (
    <>
      {dataSecurityTypeList()}
      <Tabs onChange={callback} type="card">
        <TabPane tab="Core Service" key="1">
          <DataSecurityService roleKey={role.roleKey} />
        </TabPane>
        {/* <TabPane tab="CMS Service" key="2">
          <DataSecurityService />
        </TabPane> */}
      </Tabs>
    </>
  )
}

export default DataSecurityRolePage
