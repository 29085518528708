import { Select } from 'antd'

import { IDataSecurityData } from 'components/Table/AppRole'
import DataSecurityTable from 'components/Table/AppRole/DataSecurity'
import { ColumnsType } from 'antd/lib/table'

import imgNone from 'assets/images/data_security/none.png'
import imgOrganization from 'assets/images/data_security/organization.png'
import imgParent from 'assets/images/data_security/parent.png'
import imgSystem from 'assets/images/data_security/system.png'
import imgUser from 'assets/images/data_security/user.png'
import useGetDataSecurityRole, { DataSecurityRoleAPIPayload } from 'graphQL/useGetDataSecurityRole/useDataSecurityRole'
import { useEffect, useState } from 'react'

const dataSecurityImg = (key: string | undefined): JSX.Element => {
  let img = imgNone
  switch (key) {
    case 'ORGANIZATION':
      img = imgOrganization
      break
    case 'USER':
      img = imgUser
      break
    case 'SYSTEM':
      img = imgSystem
      break
    case 'PARENT_CHILD':
      img = imgParent
      break
  }
  return (
    <>
      <img src={img} alt=""></img>
    </>
  )
}
const Option = Select.Option
function DataSecurityService({ roleKey }: { roleKey: string }): JSX.Element {
  const [dataSource, setDataSource] = useState<DataSecurityRoleAPIPayload[] | any>()
  const { data } = useGetDataSecurityRole({
    variables: {
      roleKey: roleKey,
    },
  })
  console.log(data)
  useEffect(() => {
    const dataSecurityList = data?.getDataSecurityRole.payload
    setDataSource(dataSecurityList)
  }, [data])
  const columns: ColumnsType<IDataSecurityData> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Security Key',
      dataIndex: 'securityKey',
      key: 'securityKey',
    },
    {
      title: 'Create',
      dataIndex: 'create',
      key: 'create',
      render: (_text, render) => {
        return dataSecurityImg(_text)
      },
    },
    {
      title: 'Read',
      dataIndex: 'read',
      key: 'read',
      render: (_text, render) => {
        return dataSecurityImg(_text)
      },
    },
    {
      title: 'Write',
      dataIndex: 'write',
      key: 'write',
      render: (_text, render) => {
        return dataSecurityImg(_text)
      },
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      key: 'delete',
      render: (_text, render) => {
        return dataSecurityImg(_text)
      },
    },
    {
      title: 'Assign',
      dataIndex: 'assign',
      key: 'assign',
      render: (_text, render) => {
        return dataSecurityImg(_text)
      },
    },
    {
      title: 'Assign To Parent',
      dataIndex: 'assignToParent',
      key: 'assignToParent',
      render: (_text, render) => {
        return (
          <Select defaultValue={_text || 'NO'}>
            <Option value="YES">Yes</Option>
            <Option value="NO">No</Option>
          </Select>
        )
      },
    },
  ]
  return (
    <>
      <DataSecurityTable dataSource={dataSource} columns={columns} rowKey={'permissionCoreService'} />
    </>
  )
}

export default DataSecurityService
