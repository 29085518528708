import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAuthContext } from 'contexts/AuthProvider'
import { usePermissionContext } from 'contexts/PermissionProvider'

import { checkTokenExpired } from 'utils/token'
import { appLocalAccessToken, appLocalRefreshToken } from 'utils/localService/app'

import useRefreshAccessToken from 'graphQL/useRefreshAccessToken'
import useGetMyProfile from 'graphQL/useGetMyProfile'
import useGetMyPermission from 'graphQL/useGetMyPermission'

import useGenerateOAuthLink from 'hooks/useGenerateOAuthLink'

import FullScreenLoading from 'components/FullScreenLoading'

import { goToSignInPage } from 'config'

const withAuth =
  <T extends object>(WrapComponent: React.ComponentType<T>): React.FC<T> =>
  (props) => {
    const { t } = useTranslation()

    const accessToken = appLocalAccessToken.get()
    const refreshToken = appLocalRefreshToken.get()

    const auth = useAuthContext()
    const permission = usePermissionContext()
    const oauth = useGenerateOAuthLink()

    const [isAccessTokenExpired, setAccessTokenExpired] = useState(checkTokenExpired(accessToken))

    const shouldSignInFirst = !Boolean(accessToken && refreshToken)
    const shouldSkip = isAccessTokenExpired || shouldSignInFirst
    const shouldRefreshToken = Boolean(isAccessTokenExpired && refreshToken)

    const [refreshAccessToken, refreshAccessTokenResp] = useRefreshAccessToken({
      onCompleted(resp) {
        auth.signIn(resp.refreshAccessToken.payload)

        setAccessTokenExpired(false)
      },
    })

    const permissionQuery = useGetMyPermission({
      skip: shouldSkip,
      onCompleted(resp) {
        permission.setPermissions(resp.getMyPermission.payload)
      },
    })

    const myProfileQuery = useGetMyProfile({
      skip: shouldSkip,
      fetchPolicy: 'network-only',
      onCompleted() {
        auth.signIn({
          accessToken: String(accessToken),
          refreshToken: String(refreshToken),
        })
      },
    })

    useEffect(() => {
      if (shouldRefreshToken) {
        refreshAccessToken({
          variables: {
            refreshToken: String(refreshToken),
          },
        })
      }
    }, [refreshAccessToken, isAccessTokenExpired, refreshToken, shouldRefreshToken])

    if (refreshAccessTokenResp.loading || myProfileQuery.loading || oauth.loading || permissionQuery.loading) {
      return null
    }

    if (shouldSignInFirst) {
      goToSignInPage(oauth.url)

      return <FullScreenLoading loadingText={t('auth:whileRedirectToSignInPage')} />
    }

    return <WrapComponent {...props} />
  }

export default withAuth
