import React, { useState, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { changeAntdTheme } from 'dynamic-antd-theme'

import FullScreenLoading from 'components/FullScreenLoading'

import useGetAppTheme from 'graphQL/useGetAppTheme'

import API from 'constants/api'

import type { IDefaultProviderProps } from '../types'
import type { AppThemeContextData } from './types'
import type { AppThemeAPIPayload, ThemeColor } from 'graphQL/useGetAppTheme/interface'
import useGetCredentialByHost from 'graphQL/useGetCredentialByHost'

const defaultThemeContextData: AppThemeContextData = {
  admin: {
    appKey: '',
    themeKey: '',
    themeType: 'ADMIN',
    status: 'ACTIVE',
    isDefault: 'YES',
    isReplaceable: null,
    name: null,
    color: null,
    text: null,
    image: null,
    custom: null,
  },
  collapsed: false,
  collapseHandler() {},
}

export const ThemeContext = React.createContext<AppThemeContextData>(defaultThemeContextData)

function ThemeProvider({ children }: IDefaultProviderProps): JSX.Element {
  const [admin, setAdminTheme] = useState<AppThemeAPIPayload>(defaultThemeContextData.admin)
  const [collapsed, setCollapsed] = useState(defaultThemeContextData.collapsed)

  const credentialByHostQuery = useGetCredentialByHost({
    variables: {
      host: window.location.host,
    },
  })

  const themeQuery = useGetAppTheme({
    skip: credentialByHostQuery.data == null,
    context: {
      uri: API.CORE.SYSTEM_ADMIN,
      headers: {
        credentialKey: credentialByHostQuery.data?.getCredentialByHost.payload.credential.credentialKey,
      },
    },
    onCompleted(resp) {
      onThemeHandler(resp.getAppTheme.payload[0])
    },
  })

  function onThemeHandler(theme: AppThemeAPIPayload) {
    setupTheme(theme)
    setAdminTheme(theme)
  }

  function collapseHandler(isCollapsed: boolean) {
    setCollapsed(isCollapsed)
  }

  function setupTheme(theme: AppThemeAPIPayload) {
    setupThemeColor(theme.color)

    if (theme.image) {
      const { favIcon } = theme.image
      const favEle = document.getElementById('favicon') as HTMLLinkElement
      if (favIcon && favEle) {
        favEle.href = favIcon
      }
    }
  }

  function setupThemeColor(color: ThemeColor | null) {
    const fallbackColor = {
      primary: '#233060',
      secondary: '#bfbfbf',
      text: '#000',
      navText: '#fff',
    }

    const primary = color?.primary || fallbackColor.primary
    const secondary = color?.secondary || fallbackColor.primary
    const text = color?.titleText || fallbackColor.text

    changeAntdTheme(primary)

    document.documentElement.style.setProperty('--app-primaryColor', primary)
    document.documentElement.style.setProperty('--app-secondaryColor', secondary)
    document.documentElement.style.setProperty('--app-titleTextColor', text)
    document.documentElement.style.setProperty('--app-navBgColor', color?.navBg || primary)
    document.documentElement.style.setProperty('--app-navTextColor', color?.navText || fallbackColor.navText)
    document.documentElement.style.setProperty('--app-navAuthBgColor', color?.navAuthBg || '#fff')
    document.documentElement.style.setProperty('--app-navAuthTextColor', color?.navAuthText || primary)
  }

  return (
    <ThemeContext.Provider
      value={{
        admin,
        collapsed,
        collapseHandler,
      }}
    >
      <Helmet>
        <title>{admin.text?.webTitle}</title>
      </Helmet>
      {themeQuery.loading ? <FullScreenLoading /> : children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider

export const useThemeContext = () => useContext(ThemeContext)
