import ContentCard from 'components/Card/Content'
// import PageTitle from 'components/PageTitle'
// import { Button, Space } from 'antd'
// import { PlusCircleOutlined } from '@ant-design/icons'
import AdministratorDashboard from 'components/Dashboard/SystemAdmin/SystemAdministrator'

function Administrator(): JSX.Element {
  // function goToCreateAdministrator() {
  //   history.push('/system-admin/admin/create')
  // }
  return (
    <>
      {/* <PageTitle
        title="All Administrator"
        extra={[
          <Button type="primary" key="create">
            <Space size={12}>
              <PlusCircleOutlined />
              New Administrator
            </Space>
          </Button>,
        ]}
      /> */}

      <ContentCard title="All Administrator">
        <AdministratorDashboard />
      </ContentCard>
    </>
  )
}
export default Administrator
