import { message } from 'antd'

import apolloClient from 'constants/initializeApolloClient'

import type { ApolloError } from '@apollo/client'
import type { GraphQLError } from 'graphql'

export const isTokenExpiredError = (graphQLErrors: ReadonlyArray<GraphQLError>) => {
  return graphQLErrors[0].extensions?.code === 'TOKEN_IS_EXPIRED'
}

export const onDefaultErrorMessage = (error: ApolloError) => {
  if (isTokenExpiredError(error.graphQLErrors)) {
    apolloClient.refetchQueries({
      include: 'active',
    })
  } else {
    message.error(error.message)
  }
}
