import { useState } from 'react'
import { generatePath, useHistory } from 'react-router'
import { message } from 'antd'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import ContentCard from 'components/Card/Content'
import NewOrganizationForm from 'components/Form/Organization/NewOrganization'
import PageTitle from 'components/PageTitle'
import { PhoneNumberVerifyModal } from 'components/Modal/Organization'

import { CREATE_ORGANIZATION } from 'graphQL/schemas/organization'

import { onDefaultErrorMessage } from 'utils/apollo'

import AuthenticatedLayout from 'layouts/AuthenticatedLayout'

import { useOrganizationContext } from 'contexts/OrganizationProvider'

import { routePaths } from 'pages/routeConfig'

import type { IVerify } from '../types'

const defaultVerify: IVerify = {
  isShowDialog: false,
  isVerified: false,
}

function NewOrganization(): JSX.Element {
  const organization = useOrganizationContext()
  const { t } = useTranslation()

  const [phoneVerify] = useState<IVerify>(defaultVerify)
  const [attachFiles, setAttachFiles] = useState<any[]>([])

  const [createOrganization] = useMutation(CREATE_ORGANIZATION, {
    onCompleted(resp) {
      const key = resp.createOrganization.payload.orgKey

      message.success('Organization created.')

      organization.setOrgKey(key)

      history.push(
        generatePath(routePaths.organizationDetail, {
          orgKey: key,
          slug: 'setting',
        })
      )
    },
    onError(error) {
      onDefaultErrorMessage(error)
    },
  })

  const history = useHistory()

  function onBack() {
    history.goBack()
  }

  function onFinish(values: any) {
    createOrganization({
      variables: {
        input: {
          parentOrgKey: values.parentOrganization || '',
          name: values.organizationName,
          contactName: values.contactName,
          contactEmailList: [values.contactEmail],
          attribute: {
            phoneNumber: {
              countryCode: values.prefix,
              number: values.phoneNumber,
            },
            contactLanguage: values.contactLanguage,
          },
        },
      },
    })
  }

  return (
    <AuthenticatedLayout>
      <div className="admin-content-container">
        <PageTitle title={t('organization:new.NewOrganization')} onBack={onBack} />
        <ContentCard title={t('organization:new.createNewOrganization')}>
          <NewOrganizationForm onFinish={onFinish} attachFiles={attachFiles} setAttachFiles={setAttachFiles} />
          <PhoneNumberVerifyModal phoneVerify={phoneVerify} />
        </ContentCard>
      </div>
    </AuthenticatedLayout>
  )
}

export default NewOrganization
